import { CarouselBase } from 'src/components/Carousel/CarouselBase';
import { ClientCasesProps } from 'src/components/Carousel/types';
import { Title } from 'src/components/ConstructorBlocks/Title';
import { Wrapper } from 'src/components/Wrapper';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import s from './ClientCasesCarousel.module.scss';

interface ClientCasesCarouselProps {
  slides: ClientCasesProps[];
  title?: string;
  note?: string;
}

export function ClientCasesCarousel({
  slides,
  title,
  note,
}: ClientCasesCarouselProps) {
  const {
    windowWidth,
    breakpoints: { CAROUSEL_NAVIGATION, MD },
  } = useWindowWidth();

  return (
    <section className={s.root} data-qa="client-cases-carousel">
      {title && <Title title={title} />}
      {note && (
        <Wrapper>
          <span className={s.note}>{note}</span>
        </Wrapper>
      )}
      <div className={s.wrapper}>
        <CarouselBase
          autoPlay
          slidesCount={slides.length}
          withNavigation={!!windowWidth && windowWidth > CAROUSEL_NAVIGATION}
          withPagination
          loop
          paginationBulletClassName={s.bullet}
          carouselClassName={s.carousel}
          navigationClassName={s.navigation}
          slideClassName={s.slide}
          slidesPerView={windowWidth && windowWidth <= MD ? 1 : 2}
          slidesSpacing={16}
        >
          {slides.map(
            ({ title, category, image, description, link }, index) => (
              <CarouselBase.ClientCases
                key={index}
                title={title}
                link={link}
                category={category}
                image={image}
                description={description}
              />
            ),
          )}
        </CarouselBase>
      </div>
    </section>
  );
}
