import cn from 'classnames';
import { Wrapper } from 'src/components/Wrapper';

import s from './Title.module.scss';

export function Title({
  title,
  className = '',
}: {
  title: string;
  className?: string;
}) {
  return (
    <Wrapper>
      {title && (
        <h2
          className={cn(s.root, className)}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
    </Wrapper>
  );
}
