import { CarouselBase } from 'src/components/Carousel/CarouselBase';
import { CaseProps } from 'src/components/Carousel/types';
import { Wrapper } from 'src/components/Wrapper';

import s from './Cases.module.scss';

interface CasesProps {
  slides: CaseProps[];
  plug?: { title: string; description: string };
  bgClassName?: string;
}

export function CasesCarousel({ slides, plug, bgClassName }: CasesProps) {
  return (
    <section className={s.root} data-qa="cases-carousel">
      {plug && (
        <Wrapper>
          <div className={s.plug}>
            <span className={s.plugTitle}>{plug.title}</span>
            <span className={s.plugDesc}>{plug.description}</span>
          </div>
        </Wrapper>
      )}

      <CarouselBase
        autoPlay
        interval={8000}
        slidesCount={slides.length}
        withPagination
        loop
        paginationBulletClassName={s.bullet}
        arrowClassName={s.arrow}
        className={s.carousel}
      >
        {slides.map(
          ({
            id,
            title,
            text,
            buttonTitle,
            link,
            image,
            additionalText,
            withoutContent,
          }) => (
            <CarouselBase.Case
              key={id}
              title={title}
              text={text}
              buttonTitle={buttonTitle}
              link={link}
              image={image}
              additionalText={additionalText}
              withoutContent={withoutContent}
              bgClassName={bgClassName}
            />
          ),
        )}
      </CarouselBase>
    </section>
  );
}
