import cn from 'classnames';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { Warp } from 'src/types';
import { Appearance } from 'src/uikit/Tag/constants';
import { detectExternalLink } from 'src/utils/detectExternalLink';

import { CarouselBase } from '../../CarouselBase';
import { DatePositionType } from '../../types';
import s from './WarpsCarousel.module.scss';

function Arrow() {
  return (
    <svg
      className={s.arrow}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0839 33.33L13 31.2233L24.5583 19.665L13 8.10667L15.0839 6L28.7489 19.665L15.0839 33.33Z"
        fill="#4E4E4E"
      />
    </svg>
  );
}

export interface WarpsCarouselProps {
  id?: string;
  title: string;
  datePosition?: DatePositionType;
  slides: Warp[];
  className?: string;
  tagAppearance?: Appearance;
}

export function WarpsCarousel({
  id,
  title,
  slides,
  className,
  datePosition,
  tagAppearance,
}: WarpsCarouselProps) {
  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  return (
    <section className={cn(s.root, className)} data-qa="warps-carousel" id={id}>
      <div className={s.container}>
        <header className={s.header}>
          <h2 className={s.title} dangerouslySetInnerHTML={{ __html: title }} />
        </header>
      </div>

      <div className={s.carouselWrapper}>
        <CarouselBase
          rewind
          slidesPerView={3}
          slidesSpacing={32}
          slidesCount={slides.length}
          withNavigation={!!windowWidth && windowWidth > MD}
          withPagination
          paginationBulletClassName={s.bullet}
          carouselClassName={s.carousel}
          navigationClassName={s.buttons}
          arrowClassName={s.arrow}
          customArrow={<Arrow />}
          autoPlay
          breakpoints={{
            0: {
              slidesPerView: 1.1,
            },
            637: {
              slidesPerView: 2.2,
            },
            829: {
              slidesPerView: 3,
            },
          }}
        >
          {slides.map(
            ({
              id,
              title,
              cover,
              releaseDate,
              categoryTitle,
              categories,
              href,
            }) => {
              const isExternalLink = detectExternalLink(href);
              const formattedHref = isExternalLink
                ? href
                : `/${categories[0].slug}/${href}`;
              return (
                <CarouselBase.Warp
                  key={id}
                  title={title}
                  cover={cover}
                  createdAt={releaseDate}
                  datePosition={datePosition}
                  categoryTitle={categoryTitle}
                  href={formattedHref}
                  tagAppearance={tagAppearance}
                />
              );
            },
          )}
        </CarouselBase>
      </div>
    </section>
  );
}
