import cn from 'classnames';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { TariffSlide } from 'src/types';

import { CarouselBase } from '../../CarouselBase';
import s from './TariffsCarousel.module.scss';

export interface TariffsCarouselProps {
  id?: string;
  title: string;
  slides: TariffSlide[];
  className?: string;
}

export function TariffsCarousel({
  id,
  title,
  slides,
  className,
}: TariffsCarouselProps) {
  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  return (
    <section className={cn(s.root, className)} data-qa="warps-carousel" id={id}>
      <div className={s.container}>
        <header className={s.header}>
          <h2 className={s.title} dangerouslySetInnerHTML={{ __html: title }} />
        </header>
      </div>
      <div className={s.carouselWrapper}>
        <CarouselBase
          rewind
          slidesPerView={3}
          slidesSpacing={windowWidth && windowWidth > MD ? 32 : 16}
          slidesCount={slides.length}
          withPagination
          paginationBulletClassName={s.bullet}
          carouselClassName={s.carousel}
          autoPlay
          breakpoints={{
            0: {
              slidesPerView: 1.1,
            },
            637: {
              slidesPerView: 1.6,
            },
            829: {
              slidesPerView: 3,
            },
          }}
        >
          {slides.map(({ title, price, features }) => {
            return (
              <CarouselBase.Tariff
                key={title}
                title={title}
                price={price}
                features={features}
              />
            );
          })}
        </CarouselBase>
      </div>
    </section>
  );
}
