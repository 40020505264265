import cn from 'classnames';
import React, { ReactNode } from 'react';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import { CarouselBase } from '../../CarouselBase';
import s from './CasesCarouselNew.module.scss';

function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="36"
      viewBox="0 0 16 36"
      fill="none"
    >
      <path
        d="M14 34.0005L2 18.0005L14 2.00049"
        stroke="#7A7E83"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

type SlideVariant = 'case' | 'product' | 'warp';

type WithoutSlides = {
  slides?: undefined;
  children: ReactNode[];
};

type WithSlides = {
  slides: {
    title: string;
    text: string;
    image: string;
    buttonTitle?: string;
    buttonLink?: string;
    buttonID?: string;
    classNameLink?: string;
    onButtonClick?: () => void;
  }[];
  children?: undefined;
};

type BaseCarouselType = {
  id?: string;
  subtitle?: string;
  title?: string;
  autoPlay?: boolean;
  showPaginationDots?: boolean;
  className?: string;
  classNameWrapper?: string;
  slideVariant?: SlideVariant;
  slideClassName?: string;
  slidesPerView?: number;
  alwaysShowPaginationDots?: boolean;
  breakpointsForCarousel?: {
    [key: number]: {
      slidesPerView?: number | 'auto';
      spaceBetween?: number;
    };
  };
} & (WithSlides | WithoutSlides);

export const CasesCarouselNew = React.forwardRef<
  HTMLDivElement,
  BaseCarouselType
>(
  (
    {
      id,
      subtitle,
      title,
      autoPlay,
      showPaginationDots,
      alwaysShowPaginationDots,
      className = '',
      classNameWrapper = '',
      slides,
      slideVariant,
      slideClassName,
      slidesPerView,
      breakpointsForCarousel,
      children,
      ...rest
    },
    ref,
  ) => {
    const { windowWidth } = useWindowWidth();

    const carouselOptions = {
      slidesCount: slides ? slides.length : children.length,
      slidesPerView: slidesPerView ? slidesPerView : 1,
      autoPlay: autoPlay,
      withPagination: showPaginationDots,
      withNavigation: windowWidth && windowWidth > 1340 ? true : false,
      loop: true,
      arrowClassName: s.arrows,
      navigationClassName: s.arrowsWrapper,
      customArrow: <Arrow />,
      alwaysShowPaginationDots,
      breakpoints: breakpointsForCarousel,
    };

    return (
      <section
        className={cn(s.root, className, {
          [s.soloSlider]: slidesPerView && slidesPerView <= 1,
        })}
        ref={ref}
        id={id}
        {...rest}
      >
        <div className={cn(s.wrapper, classNameWrapper)}>
          {subtitle && (
            <h3
              className={cn(s.subtitle, {
                [s.withoutTitle]: !title,
              })}
            >
              {subtitle}
            </h3>
          )}
          {title && <h2 className={s.title}>{title}</h2>}

          <CarouselBase {...carouselOptions}>
            {children
              ? children
              : slides.map(
                  (
                    {
                      title,
                      text,
                      buttonTitle,
                      image,
                      buttonLink,
                      onButtonClick,
                      buttonID,
                      classNameLink,
                    },
                    index,
                  ) => (
                    <CarouselBase.CaseNew
                      title={title}
                      text={text}
                      buttonTitle={buttonTitle}
                      image={image}
                      buttonLink={buttonLink}
                      onButtonClick={onButtonClick}
                      buttonID={buttonID}
                      classNameLink={classNameLink}
                      key={index}
                      slideVariant={slideVariant}
                      slideClassName={slideClassName}
                    />
                  ),
                )}
          </CarouselBase>
        </div>
      </section>
    );
  },
);

CasesCarouselNew.displayName = 'Carousel';
