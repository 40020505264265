export const getImageParams = (image: string) => {
  const indexOfParams = image.indexOf('?');

  if (indexOfParams === -1) return {};

  const replacedImage = image.substring(0, indexOfParams);
  const params = image
    .substring(indexOfParams + 1)
    .replace(/[w|h]=/gm, '')
    .split('&');

  return { width: +params[0], height: +params[1], replacedImage };
};
