import cn from 'classnames';
import { CarouselBase } from 'src/components/Carousel/CarouselBase';
import { Title } from 'src/components/ConstructorBlocks/Title';
import { Wrapper } from 'src/components/Wrapper';

import s from './AdvantagesCarousel.module.scss';

const DATA = {
  title: 'Cloud.ru – ведущий провайдер облачных и AI‑технологий',
  mark: '*По объему выручки AI-сервисов за 2022 год (по данным CNews Analytics), PaaS-сервисов за 2023 год (по данным iKS-Consulting), по темпам роста IaaS-сервисов за 2021 год (по данным iKS-Consulting).',
  slides: [
    {
      id: '1',
      title: 'по темпам роста IaaS',
      subtitle: 'iKS-Consulting, 2022',
      video: 'https://cdn-video.cloud.ru/backend/video/about-page/1.mp4',
      preview: 'https://cdn.cloud.ru/backend/images/about/preview1.png',
    },
    {
      id: '2',
      title: 'в сегменте PaaS',
      subtitle: 'iKS-Consulting, 2023',
      video: 'https://cdn-video.cloud.ru/backend/video/about-page/2.mp4',
      preview: 'https://cdn.cloud.ru/backend/images/about/preview2.png',
    },
    {
      id: '3',
      title: 'на рынке AI',
      subtitle: 'CNews Analytics, 2023',
      video: 'https://cdn-video.cloud.ru/backend/video/about-page/3.mp4',
      preview: 'https://cdn.cloud.ru/backend/images/about/preview3.png',
    },
  ],
};

interface AdvantagesCarouselProps {
  title?: string;
  note?: string;
  className?: string;
}

export function AdvantagesCarousel({
  title,
  note,
  className = '',
}: AdvantagesCarouselProps) {
  return (
    <section
      className={cn(s.root, className)}
      data-qa="advantages-carousel"
      data-attribute="no_search_index"
    >
      <Title title={title || DATA.title} className={s.title} />
      {note && (
        <Wrapper>
          <div className={s.note} dangerouslySetInnerHTML={{ __html: note }} />
        </Wrapper>
      )}
      <Wrapper className={s.carouselWrapper}>
        <CarouselBase
          autoPlay
          slidesCount={DATA.slides.length}
          withPagination
          paginationBulletClassName={s.bullet}
          carouselClassName={s.carousel}
          navigationClassName={s.navigation}
          slideClassName={s.slide}
          slidesSpacing={16}
          slidesPerView={3}
          breakpoints={{
            0: {
              slidesPerView: 1.1,
            },
            475: {
              slidesPerView: 1.3,
            },
            636: {
              slidesPerView: 1.6,
            },
            729: {
              slidesPerView: 2.1,
            },
            829: {
              slidesPerView: 3,
            },
          }}
        >
          {DATA.slides.map(({ title, subtitle, video, id, preview }) => (
            <CarouselBase.Advantages
              key={id}
              preview={preview}
              title={title}
              subtitle={subtitle}
              video={video}
            />
          ))}
        </CarouselBase>
      </Wrapper>
      <Wrapper>
        <span className={s.mark}>{DATA.mark}</span>
      </Wrapper>
    </section>
  );
}
