import cn from 'classnames';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import { CarouselBase } from '../../CarouselBase';
import s from './Main.module.scss';

function Arrow() {
  return (
    <svg
      className={s.arrow}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 48L38 32L26 16"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

interface MainProps {
  className?: string;
  slides: {
    id: string;
    title?: string;
    link: string;
    image: string;
  }[];
}

export function MainCarousel({ className = '', slides }: MainProps) {
  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  return (
    <CarouselBase
      autoPlay
      slidesCount={slides.length}
      withNavigation={!!windowWidth && windowWidth > MD}
      withPagination={!!windowWidth && windowWidth <= MD}
      withProgressBar
      loop
      paginationBulletClassName={s.bullet}
      progressBarClassName={s.progressbar}
      className={cn(s.root, className)}
      carouselClassName={s.carousel}
      navigationClassName={s.navigation}
      customArrow={<Arrow />}
      effect="fade"
      speed={1000}
      slideClassName={s.slide}
    >
      {slides.map(({ id, title, image, link }) => (
        <CarouselBase.Main title={title} image={image} link={link} key={id} />
      ))}
    </CarouselBase>
  );
}
