import cn from 'classnames';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { getImageParams } from 'src/utils/getImageParams';

import { CarouselBase } from '../../CarouselBase';
import { ClientSlideProps } from '../../types';
import s from './ClientsCarousel.module.scss';

function Arrow() {
  return (
    <svg
      className={s.arrow}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0839 33.33L13 31.2233L24.5583 19.665L13 8.10667L15.0839 6L28.7489 19.665L15.0839 33.33Z"
        fill="black"
      />
    </svg>
  );
}

interface ClientsProps {
  title: string;
  slides: ClientSlideProps[];
  className?: string;
  withNavigation?: boolean;
  slidesPerView?: 'auto' | number;
  carouselClassName?: string;
  carouselWrapperClassName?: string;
  carouselTitleClassName?: string;
  carouselContainerInnerClassName?: string;
  onClick?(id: string): void;
}

export function ClientsCarousel({
  title,
  slides,
  className = '',
  withNavigation = true,
  slidesPerView = 'auto',
  carouselClassName = '',
  carouselWrapperClassName = '',
  carouselTitleClassName = '',
  carouselContainerInnerClassName = '',
  onClick,
}: ClientsProps) {
  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();
  const stylesForSlides = slides.map(({ image }) => {
    const { width, height } = getImageParams(image);

    return {
      width: `${width}px`,
      height: `${height}px`,
    };
  });

  const breakpointsForCarousel = {
    0: {
      spaceBetween: 18,
    },
    700: {
      spaceBetween: 15,
    },
    736: {
      spaceBetween: 26,
    },
    829: {
      spaceBetween: 48,
    },
  };

  return (
    <section className={cn(s.root, className)} data-qa="clients-carousel">
      <div className={s.container}>
        {windowWidth && windowWidth <= MD && (
          <div
            className={cn(s.title, carouselTitleClassName)}
            data-qa="clients-carousel_title"
          >
            {title}
          </div>
        )}
        <div className={cn(s.containerInner, carouselContainerInnerClassName)}>
          {windowWidth && windowWidth > MD && (
            <div
              className={cn(s.title, s.titleDesk, carouselTitleClassName)}
              data-qa="clients-carousel_title"
            >
              {title}
            </div>
          )}
          <CarouselBase
            rewind
            withNavigation={withNavigation}
            slidesPerView={slidesPerView}
            slidesCount={slides.length}
            customArrow={<Arrow />}
            slideClassName={s.slide}
            carouselClassName={cn(s.carousel, carouselClassName)}
            navigationClassName={s.buttons}
            slidesStyles={stylesForSlides}
            className={cn(s.carouselWrapper, carouselWrapperClassName)}
            breakpoints={breakpointsForCarousel}
          >
            {slides.map(({ image, link, id }) => {
              const { replacedImage } = getImageParams(image);

              if (replacedImage) {
                return (
                  <CarouselBase.Client
                    key={id}
                    image={replacedImage}
                    link={link}
                    onClick={onClick}
                    targetSlideId={id}
                  />
                );
              }

              return null;
            })}
          </CarouselBase>
        </div>
      </div>
    </section>
  );
}
